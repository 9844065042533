import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"

const HistoryPage = props => (
  <CommunityLayout pageTitle="History">
    <p>Stuff goes here...</p>
  </CommunityLayout>
)

export default HistoryPage
